<template>
  <VueAnnouncer class="sr-only" />
  <v-card>
    <v-layout>
      <v-app-bar :title="title">
        <template v-slot:prepend
          v-if="(userOrganizations && userOrganizations.length > 0) || (userLocations && userLocations.length > 0)">
          <v-app-bar-nav-icon @click="drawer = !drawer" aria-label="Organizations List"
            aria-hidden="false"></v-app-bar-nav-icon>
        </template>
        <v-spacer></v-spacer>
        <!--Create Organization Button-->
        <v-tooltip text="Tooltip"
          v-if="this.authStore.userInfo && !isLoading && (!userOrganizations || userOrganizations.length < 1) && (!userLocations || userLocations.length < 1)">
          <template v-slot:activator="{ props }">
            <v-btn :icon="mdiPlus" variant="text" v-bind="props" :to="getNewOrganizationRoute"
              aria-label="Create Organization" aria-hidden="false">
            </v-btn>
          </template>
          <span>Create Organization</span>
        </v-tooltip>
        <v-tooltip text="Tooltip"
          v-if="this.authStore.userInfo && !isLoading && (userOrganizations && userOrganizations.length > 0) && PrimaryOrganizationId">
          <template v-slot:activator="{ props }">
            <v-btn :icon="mdiDomain" variant="text" v-bind="props" :to="'/Organization/' + PrimaryOrganizationId"
              aria-label="Edit Organization" aria-hidden="false">
            </v-btn>
          </template>
          <span>Edit Organization</span>
        </v-tooltip>
        <v-tooltip text="Tooltip"
          v-if="this.authStore.userInfo && !isLoading && (userLocations && userLocations.length > 0) && PrimaryLocation">
          <template v-slot:activator="{ props }">
            <v-btn :icon="mdiStoreCog" variant="text" v-bind="props"
              :to="'/Organization/' + PrimaryLocation.organizationId + '/Location/' + PrimaryLocation.id"
              aria-label="Edit Location" aria-hidden="false">
            </v-btn>
          </template>
          <span>Edit Location</span>
        </v-tooltip>
        <v-tooltip text="Tooltip">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" @click="themeToggler = !themeToggler" aria-label="Change Theme"
              aria-hidden="false"
              v-if="theme && theme.global && theme.global.current && theme.global.current.value && theme.global.current.value.dark"
              :icon="mdiWeatherNight"></v-btn>
            <v-btn variant="text" v-bind="props" @click="themeToggler = !themeToggler" v-else
              :icon="mdiWeatherSunny"></v-btn>
          </template>
          <span>Change Theme</span>
        </v-tooltip>
        <v-tooltip text="Tooltip">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" :href="getLoginRoute" :aria-label="loginButtonText" aria-hidden="false">
              <v-icon>{{ loginButtonIcon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ loginButtonText }}</span>
        </v-tooltip>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer"
        v-if="drawer && (userOrganizations && userOrganizations.length > 0) || (userLocations && userLocations.length > 0)"
        temporary>
        <v-list>
          <v-list-subheader v-if="userOrganizations && userOrganizations.length > 0">Organizations</v-list-subheader>
          <v-list-item v-for="Organization in userOrganizations" :key="Organization.id"
            :to="'/Organization/' + Organization.id">
            <v-list-item-title>{{ Organization.name }}</v-list-item-title>
          </v-list-item>
          <v-list-subheader v-if="userLocations && userLocations.length > 0">Locations</v-list-subheader>
          <v-list-item v-for="Location in userLocations" :key="Location.id"
            :to="'/Organization/' + Location.organizationId + '/Location/' + Location.id">
            <v-list-item-title>{{ Location.displayName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { useTheme, useDisplay } from "vuetify";
import { ref, watch, computed } from "vue";
import { useAuthStore } from "@/stores/store";
import { mdiLogin, mdiLogout, mdiAccountKey, mdiDomain, mdiPlus, mdiWeatherNight, mdiWeatherSunny, mdiStoreCog } from "@mdi/js";
export default {
  data() {
    return {
      mdiLogin,
      mdiLogout,
      mdiAccountKey,
      mdiDomain,
      mdiPlus,
      mdiWeatherNight,
      mdiWeatherSunny,
      mdiStoreCog,
      theme: useTheme(),
      display: useDisplay(),
      drawer: false,
      themeToggler: false,
      authStore: useAuthStore(),
      userOrganizations: computed(() => this.authStore.userOrganizations),
      userLocations: computed(() => this.authStore.userLocations),
      isLoading: false,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  watch: {
    themeToggler() {
      this.theme.global.name = this.theme.global.current.dark ? "light" : "dark";
    }
  },
  computed: {
    isMobile() {
      if (this.display)
        return this.display.mobile;
      else
        return false;
    },
    loginButtonText() {
      return this.authStore.userInfo ? "Logout" : "Login";
    },
    loginButtonIcon() {
      return this.authStore.userInfo ? mdiLogout : mdiAccountKey;
    },
    getLoginRoute() {
      return this.authStore.userInfo ? "/.auth/logout" : "/login";
    },
    getNewOrganizationRoute() {
      return "/Organization/null";
    },
    PrimaryOrganizationId() {
      if (this.userOrganizations && this.userOrganizations.length > 0)
        return this.userOrganizations[0].id;
      return null;
    },
    PrimaryLocation() {
      if (this.userLocations && this.userLocations.length > 0)
        return this.userLocations[0];
      return null;
    },
    title() {
      let CurrentRoute = this.$route;
      if (CurrentRoute && CurrentRoute.meta && CurrentRoute.meta.title)
        return CurrentRoute.meta.title;
      else if (!this.isMobile) {
        return 'Dev2 Appointments, Bookings, and Reservations'
      }
      return null;
    }
  },
  methods: {
    //Get UserInfo from the SWA .auth/me endpoint
    async getUserInfo() {
      try {
        const response = await fetch("/.auth/me", { credentials: "include" });
        const payload = await response.json();
        const { clientPrincipal } = payload;
        //console.debug("clientPrincipal", clientPrincipal);
        if (clientPrincipal) {
          // The request is authenticated.
          this.authStore.setUserInfo(clientPrincipal);
          // Update the user's Organizations in the store
          this.updateUserOrganizationsAndLocations();
          // Update the User
          fetch("/api/person", {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(clientPrincipal),
          });
        } else {
          // The request is not authenticated.
          this.authStore.setUserInfo(null);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
    async updateUserOrganizationsAndLocations() {
      this.isLoading = true;
      try {
        const response = await fetch("/api/OrganizationsAndLocations", { credentials: "include" });
        this.isLoading = false;
        const data = await response.json();
        // Update the user's Organizations in the store
        this.authStore.setUserOrganizationsAndLocations(data);
        if (this.isMobile === false && (this.userOrganizations && this.userOrganizations.length > 0) || (this.userLocations && this.userLocations.length > 0)) {
          //this.drawer = true;
        }
      } catch (error) {
        console.error("Error fetching user Organizations:", error);
      }
    },
  },
}
</script>
