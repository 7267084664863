import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as labs from 'vuetify/labs/components';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { mdiLogin, mdiLogout, mdiAccountKey, mdiDomain, mdiPlus, mdiWeatherNight, mdiWeatherSunny } from "@mdi/js";
export default createVuetify(
    {
        defaults: {
            VAutocomplete: {
                variant: 'outlined'
            },
            VTextField: {
                variant: 'outlined'
            },
            VSelect: {
                variant: 'outlined'
            },
            VTextarea: {
                variant: 'outlined'
            },
        },
        icons: {
            defaultSet: 'mdi',
            aliases: {
                ...aliases,
                mdiLogin,
                mdiLogout,
                mdiAccountKey,
                mdiDomain,
                mdiPlus,
                mdiWeatherNight,
                mdiWeatherSunny,
            },
            sets: {
                mdi,
            },
        },
        theme: {
            defaultTheme: 'dark',
            cspNonce: 'MTo3NjAxMzozNDgxODc4NTIzOjE2ODczNDk4MDA=',
        },
        components: {
            ...labs
        }
    });