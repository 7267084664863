import { createApp } from 'vue';
import App from './App.vue';
import 'vuetify/styles'
import vuetify from '@/vuetifyPlugin';
import router from './router'
import StackTrace from 'stacktrace-js';
import { registerSW } from 'virtual:pwa-register'
import { createPinia } from 'pinia';
import VueAnnouncer from '@vue-a11y/announcer'
import '@vue-a11y/announcer/dist/style.css'
import { createHead, VueHeadMixin } from "@unhead/vue"
import { InferSeoMetaPlugin } from '@unhead/addons'
import { SchemaOrgUnheadPlugin } from '@vueuse/schema-org';

try {
  (function (c, l, a, r, i, t, y) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
    t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "hp3rnmp7z1");
}
catch (ex) {
  console.error(ex);
}
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
try {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-KMG6B5MY58';
  document.head.appendChild(script);
  gtag('js', new Date());
  gtag('config', 'G-KMG6B5MY58');
}
catch (ex) {
  console.error(ex);
}
const updateSW = registerSW({
  onNeedRefresh() { },
  onOfflineReady() { },
})
registerSW({ immediate: true })
const app = createApp(App);
const head = createHead({
  plugins: [
    InferSeoMetaPlugin(),
    SchemaOrgUnheadPlugin({
      host: 'https://Appointments.DevelopingToday.net'
    }, () => {
      const route = router.currentRoute.value
      return {
        path: route.path,
        ...route.meta,
      }
    })
  ]
});
app.mixin(VueHeadMixin)
app.use(vuetify)
  .use(VueAnnouncer, { router })
  .use(router)
  .use(head)
  .use(createPinia());
router.isReady().then(() => {
  app.mount('#app');
});

var mainjsError = null;
var mainjsErrorSource = null;
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  console.log("Running in development mode. Errors will not be logged to the server.");
}
else {
  app.config.errorHandler = function (err, vm, info) {
    mainjsError = err;
    mainjsErrorSource = null;
    StackTrace.fromError(err).then(callback).catch(errback);
  }
  window.onerror = function (msg, src, linenum, colnum, error) {
    mainjsError = error;
    mainjsErrorSource = src;
    StackTrace.fromError(error).then(callback).catch(errback);
  };
}
var errback = function (err) {
  console.error(err);
};
var callback = function (stackframes) {
  var stringifiedStack = stackframes.map(function (sf) {
    return sf.toString();
  }).join('\n');
  try {
    let url = null;
    if (window && window.location) {
      url = window.location.href;
    }
    fetch("/api/Errors", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Message: mainjsError.message || "",
        Source: mainjsErrorSource, //JSON.stringify({ vm }, getCircularReplacer()),
        StackTrace: stringifiedStack,
        Path: url,
        DateOccured: new Date()
      }),
    });
    console.error(mainjsError);
  }
  catch (ex) {
    console.error(ex);
  }
};