﻿import * as VueRouter from 'vue-router';
const SplashPage = () => import('./SplashPage.vue');
const NotFound = () => import('@/404.vue');
const Forbidden = () => import('@/403.vue');
const LoginPage = () => import('./LoginPage.vue');
const TermsPage = () => import('./TermsPage.vue');
const OrganizationPage = () => import('./OrganizationPage.vue');
const LocationPage = () => import('./LocationPage.vue');
const BusinessHoursPage = () => import('./BusinessHoursPage.vue');
const HolidayPage = () => import('./HolidayPage.vue');
const ServicesPage = () => import('./ServicesPage.vue');
const AppointmentBooking = () => import('./AppointmentBooking.vue');
const AppointmentConfirmation = () => import('./ConfirmationPage.vue');
const AppointmentCancellation = () => import('./CancelationPage.vue');
const StaffPage = () => import('./StaffPage.vue');
const AppointmentsPage = () => import('./AppointmentsPage.vue');
const NotificationRulesPage = () => import('./NotificationRulesPage.vue');

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=d65edc17-d3fc-4424-8c69-f8d883e97cb7;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/'
    }
});
appInsights.loadAppInsights();

const router = VueRouter.createRouter(
    {
        history: VueRouter.createWebHistory(),
        routes: [
            {
                path: '/',
                name: 'splash',
                component: SplashPage,
                meta: { requiresAuth: false, title: 'Dev2 - Appointments, Bookings, and Reservations' },
                children: [
                ]
            },
            {
                path: '/login',
                component: LoginPage,
                meta: { requiresAuth: false, showDialog: true, title: 'Login' }
            },
            {
                path: '/Organization/:organizationId',
                component: OrganizationPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId',
                component: LocationPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/BusinessHours',
                component: BusinessHoursPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Service/:serviceId/BusinessHours',
                component: BusinessHoursPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Holidays',
                component: HolidayPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Service/:serviceId/Holidays',
                component: HolidayPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Services',
                component: ServicesPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Staff',
                component: StaffPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/Appointments',
                component: AppointmentsPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/AppointmentBooking',
                component: AppointmentBooking,
                meta: { requiresAuth: false, admin: false, title: 'Appointment Booking' },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/AdminAppointmentBooking',
                component: AppointmentBooking,
                meta: { requiresAuth: true, admin: true },
                props: true,
            },
            {
                path: '/Organization/:organizationId/Location/:locationId/NotificationRules',
                component: NotificationRulesPage,
                meta: { requiresAuth: true },
                props: true,
            },
            {
                path: '/AppointmentConfirmation/:appointmentName',
                name: 'AppointmentConfirmation',
                component: AppointmentConfirmation,
                meta: { requiresAuth: false, hideToolbar: true },
                props: true
            },
            {
                path: '/Cancel/:appointmentName',
                name: 'AppointmentCancellation',
                component: AppointmentCancellation,
                meta: { requiresAuth: false, hideToolbar: true },
                props: true
            },
            {
                path: '/forbidden',
                name: 'Forbidden',
                component: Forbidden,
                meta: { requiresAuth: false },
            },
            {
                path: '/:catchAll(.*)',
                name: "NotFound",
                component: NotFound,
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/TermsOfService',
                name: 'TermsOfService',
                component: TermsPage,
                meta: { requiresAuth: false },
            },
        ]
    });
// Route Guards

router.beforeEach(async (to, from, next) => {
    const { name, meta } = to;

    if (name === 'Forbidden') {
        appInsights.trackPageView({
            pageUrl: to.path,
        });
        // User is trying to access the Forbidden page, so allow the navigation
        next();
    } else if (meta.requiresAuth) {
        try {
            appInsights.trackPageView({
                pageUrl: to.path,
            });
            // Allow the navigation to the requested page
            next();
        } catch (error) {
            console.error('Error retrieving user information:', error);
            // Redirect to the login route or show an error page
            next('/login');
        }
    } else {
        // Allow the navigation to non-authenticated routes
        appInsights.trackPageView({
            pageUrl: to.path,
        });
        next();
    }
});

export default router;