import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        userInfo: null,
        userOrganizations: null,
        userLocations: null,
        user: null,
    }),
    actions: {
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
            if (userInfo) {
                //Parse User Info into User Object
                this.user = {
                    identityProvider: userInfo.identityProvider,
                    userId: userInfo.userId,
                    email: userInfo.userDetails,
                    roles: userInfo.userRoles,
                    claims: userInfo.claims,
                };
            }
        },
        setUserOrganizationsAndLocations(organizationsAndLocations) {
            if (!organizationsAndLocations)
                return;
            const { organizations, locations } = organizationsAndLocations;
            if (organizations)
                this.userOrganizations = organizations;
            if (locations)
                this.userLocations = locations;
        }
    },
});